import axios from "axios";
import AuthService from "../auth/auth_service.js";

export default class NotificationService extends AuthService {

  constructor() {
    super("notifications")
  }

  findNotExpired() {
    return axios({
      method: "get",
      url: this.api_url + "notifications/not-expired",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }
  findActives() {
    return axios({
      method: "get",
      url: this.api_url + "notifications/actives",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }
  updateStatus(notification) {
    return axios({
      method: "put",
      url: this.api_url + "notifications/" + notification.id + "/update-status",
      withCredentials: true,
      data: notification.status,
      headers: {
        Authorization: "Bearer " + this.token(),
        'Content-Type': 'application/json'
      },
    });
  }

}
