<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '720px' }"
    header="Fomulário de Notificação"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
    ><div class="grid">
      <div class="field col-12">
        <label for="titulo">Título</label>
        <InputText
          id="titulo"
          v-model="v$.notification.title.$model"
          maxlength="100"
          placeholder="Digite o nome do local"
          :class="{ 'p-invalid': submitted && v$.notification.title.$invalid }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.notification.title.$invalid"
          >Campo obrigatório.</small
        >
      </div>

      <div class="field col-12 md:col-3">
        <label for="notificationType">Tipo</label>
        <Dropdown
          id="notificationType"
          v-model="v$.notification.notificationType.id.$model"
          optionLabel="description"
          optionValue="id"
          :options="notificationTypes"
          placeholder="Selecione o tipo"
          :class="{
            'p-invalid':
              submitted && v$.notification.notificationType.id.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.notification.notificationType.id.$invalid"
          >Campo obrigatório.</small
        >
      </div>

      <div class="field col-12 md:col-3">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          v-model="notification.status"
          :options="Status"
          optionLabel="name"
          optionValue="key"
          placeholder="Selecione status"
        />
      </div>

      <div class="field col-12 md:col-3">
        <label for="dtCreated">Data de Inicio:</label>
        <InputText
          id="dtCreated"
          v-model="v$.notification.dtCreated.$model"
          maxlength="255"
          type="date"
          :class="{
            'p-invalid': submitted && v$.notification.dtCreated.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.notification.dtCreated.$invalid"
          >Campo obrigatório.</small
        >
      </div>

      <div class="field col-12 md:col-3">
        <label for="dtExpired">Data de Validade:</label>
        <InputText
          id="dtExpired"
          v-model="v$.notification.dtExpired.$model"
          maxlength="255"
          type="date"
          :class="{
            'p-invalid': submitted && v$.notification.dtExpired.$invalid,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && v$.notification.dtExpired.$invalid"
          >Campo obrigatório.</small
        >
      </div>

      <div class="field col-12">
        <label for="message">Mensagem</label>
        <Editor
          id="message"
          v-model="v$.notification.message.$model"
          editorStyle="height: 250px"
          :class="{
            'p-invalid': submitted && v$.notification.message.$invalid,
          }"
        >
        </Editor>

        <small
          class="p-error"
          v-if="submitted && v$.notification.message.$invalid"
        >
          Campo obrigatório.
        </small>
      </div>
    </div>

    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.notification.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Notifications from "../../../models/notifications";
import { Status } from "../../../models/enums/status";

//Services
import NotificationsService from "../../../service/notifications/notifications_service";
import NotificationTypeService from "../../../service/notification_type/notifications_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["notificationSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      notification: new Notifications(),
      notificationService: new NotificationsService(),
      notificationTypeService: new NotificationTypeService(),
      notificationTypes: [],
      submitted: false,
      Status,
    };
  },
  created() {},
  validations() {
    return {
      notification: new Notifications().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.notifications.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.notifications.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      this.notification.dtUpdated = new Date();
      if (this.notification.dtCreated > this.notification.dtExpired) {
        this.$toast.add({
          severity: "error",
          summary: "Alerta.",
          detail:
            "Data de validade é anterior a data de criação da notificação.",
          life: 6000,
        });
        return;
      }
      if (isFormValid) {
        if (this.notification.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.notificationService
        .create(this.notification)
        .then((data) => {
          if (data.status === 201) {
            this.$msgSuccess(data);
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.notificationService
        .update(this.notification)
        .then((data) => {
          if (data.status === 200) {
            this.$msgSuccess(data);
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.notification = new Notifications();
      this.submitted = false;
      this.visibleDialog = false;
    },
    getData() {
      this.notification = this.notificationSelected;
      this.notification.dtExpired = this.$DateTime.formatarDateInput(
        this.notification.dtExpired
      );
      this.notification.dtCreated = this.$DateTime.formatarDateInput(
        this.notification.dtCreated
      );
      this.getNotificationTypes();
    },
    getNotificationTypes() {
      this.notificationTypeService.findAll().then((data) => {
        this.notificationTypes = data;
      });
    },
  },
};
</script>
<style scoped>
.p-editor-toolbar button.ql-image {
  display: none;
}
</style>
