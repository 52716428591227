<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '720px' }"
    header=" "
    :modal="true"
    @hide="hideDialog"
    class="p-fluid custom-dialog"
  >
    <div v-if="currentNotification" class="notification-content">
      <h3 class="notification-title">{{ currentNotification.title }}</h3>
      <div class="notification-type-container">
        <span
          :class="[
            'notification-type',
            getTypeClass(currentNotification.notificationType.description),
          ]"
        >
          {{ currentNotification.notificationType.description }}
        </span>
      </div>
      <div
        v-html="currentNotification.message"
        class="notification-message"
      ></div>
    </div>
    <template #footer>
      <Button
        v-if="hasPrevious"
        label="Anterior"
        class="p-button p-button-text"
        @click="previousNotification"
      />
      <Button
        label="Próximo"
        v-if="!isLast"
        class="p-button p-button-text"
        @click="nextNotification"
      />
      <Button
        label="Cancelar"
        v-if="isLast"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: ["notificationSelected"],
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    currentNotification() {
      return (
        this.notificationSelected &&
        this.notificationSelected[this.currentIndex]
      );
    },
    hasPrevious() {
      return this.currentIndex > 0;
    },
    isLast() {
      return this.currentIndex === this.notificationSelected.length - 1;
    },
    visibleDialog: {
      get() {
        return this.$store.state.views.notifications.dialogNotification;
      },
      set(value) {
        this.$store.state.views.notifications.dialogNotification = value;
      },
    },
  },
  methods: {
    hideDialog() {
      this.resetDialog();
      this.visibleDialog = false;
    },
    resetDialog() {
      this.currentIndex = 0;
      this.$emit("update:modelValue", []); // Reseta o array no pai
    },
    nextNotification() {
      if (!this.isLast) {
        this.currentIndex++;
      }
    },
    previousNotification() {
      if (this.hasPrevious) {
        this.currentIndex--;
      }
    },
    getTypeClass(type) {
      switch (type.toLowerCase()) {
        case "importante":
          return "attention";
        case "manutencao":
          return "maintenance";
        case "noticia":
          return "notify";
        case "arquivo":
          return "archive";
        default:
          return "others";
      }
    },
  },
};
</script>
<style scoped>
@import url("../../../assets/demo/custom_dialog_notification.scss");
</style>