import { required } from "@vuelidate/validators";
import NotificationType from "./notification_type";

export default class Notifications {

    constructor() {
        this.id = null;
        this.title = null;
        this.message = null;
        this.notificationType = new NotificationType();
        this.dtCreated = null;
        this.dtUpdated = new Date();
        this.dtExpired = null;
        this.status = null;
    }

    validations() {
        return {
            title: {
                required,
            },
            message: {
                required,
            },
            notificationType: {
                id: {
                    required,
                }
            },
            dtExpired: {
                required,
            },
            dtCreated: {
                required,
            },
        };
    }

}